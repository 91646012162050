import React from 'react';
import _ from 'lodash';
import { ProFullscreen } from '@wix/pro-fullscreen-renderer';
import window from 'photography-client-lib/dist/src/sdk/windowWrapper';

import './WixStyles.scss';
import './FullscreenWrapper.scss';

export default class ProFullscreenWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      container: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };

    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.resize = this.resize.bind(this);
    this.debouncedResizeEvent = _.debounce(this.resize, 100);
    this.lastPageScroll = -1;

    this.actions = {
      getMoreItems: props.getMoreItems,
      close: props.closeFullscreen,
      toggleBrowserFullscreen: this.toggleBrowserFullscreen,
      navigate: item => this.onLinkNavigation(item),
    };
  }

  componentWillMount() {
    window.addEventListener('fullscreenchange', this.onFullscreenChange);
    window.addEventListener('webkitfullscreenchange', this.onFullscreenChange);
    window.addEventListener('mozfullscreenchange', this.onFullscreenChange);
    window.addEventListener('MSFullscreenChange', this.onFullscreenChange);
    window.addEventListener('resize', this.debouncedResizeEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('fullscreenchange', this.onFullscreenChange);
    window.removeEventListener(
      'webkitfullscreenchange',
      this.onFullscreenChange,
    );
    window.removeEventListener('mozfullscreenchange', this.onFullscreenChange);
    window.removeEventListener('MSFullscreenChange', this.onFullscreenChange);
    window.removeEventListener('resize', this.debouncedResizeEvent);
  }

  linkDataValidationSchema = {
    ExternalLink: ['url', 'target'],
    PageLink: ['pageId', 'pageName'],
    AnchorLink: ['anchorDataId', 'anchorName', 'pageId'],
    EmailLink: ['recipient', 'subject'],
    PhoneLink: ['phoneNumber'],
    DocumentLink: ['docId', 'name'],
  };

  onLinkNavigation(item) {
    const { linkData } = item;
    if (!linkData || !linkData.type) {
      console.error(
        'link navigation failed due to invalid link data',
        linkData,
      );
      return;
    }

    if (
      !this.linkDataValidationSchema[linkData.type] ||
      this.linkDataValidationSchema[linkData.type].some(prop => !linkData[prop])
    ) {
      console.error(
        `link navigation failed due to invalid ${linkData.type} data`,
        linkData,
      );
      return;
    }

    switch (linkData.type) {
      case 'ExternalLink':
        window.open(linkData.url, linkData.target);
        break;
      default:
        this.props.onLinkNavigation(item);
    }
  }

  resize() {
    this.setState({
      container: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  onFullscreenChange = () => {
    let container = {
      width: window.screen.width,
      height: window.screen.height,
    };

    if (
      !window.document.fullscreenElement &&
      !window.document.webkitIsFullScreen &&
      !window.document.mozFullScreen &&
      !window.document.msFullscreenElement
    ) {
      container = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }

    this.setState({ container });
  };

  // TODO: use common helper (if exists)
  toggleBrowserFullscreen() {
    if (
      !window.document.fullscreenElement &&
      !window.document.mozFullScreenElement &&
      !window.document.webkitFullscreenElement &&
      !window.document.msFullscreenElement
    ) {
      // current working methods
      if (window.document.documentElement.requestFullscreen) {
        window.document.documentElement.requestFullscreen();
      } else if (window.document.documentElement.msRequestFullscreen) {
        window.document.documentElement.msRequestFullscreen();
      } else if (window.document.documentElement.mozRequestFullScreen) {
        window.document.documentElement.mozRequestFullScreen();
      } else if (window.document.documentElement.webkitRequestFullscreen) {
        window.document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else {
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      } else if (window.document.msExitFullscreen) {
        window.document.msExitFullscreen();
      } else if (window.document.mozCancelFullScreen) {
        window.document.mozCancelFullScreen();
      } else if (window.document.webkitExitFullscreen) {
        window.document.webkitExitFullscreen();
      }
    }
  }

  blockParentScroll(shouldBlock) {
    const stopScrollClass = 'pro-gallery-stop-scroll-for-fullscreen';
    const pageHtml = window.document.getElementsByTagName('html')[0];
    const classList = pageHtml && pageHtml.classList;

    try {
      if (shouldBlock && !this.parentScrollIsBlocked) {
        this.lastPageScroll = window.scrollY;
        this.parentScrollIsBlocked = true;
        classList.add(stopScrollClass);
      } else if (!shouldBlock && this.parentScrollIsBlocked) {
        this.parentScrollIsBlocked = false;
        classList.remove(stopScrollClass);
        if (this.lastPageScroll >= 0) {
          this.lastPageScroll = -1;
          window.scrollTo(0, this.lastPageScroll);
        }
      }
    } catch (e) {
      console.log('Cannot stop parent scroll', e);
    }
  }

  render() {
    const { fullscreenIdx, items, styleParams } = this.props;

    if (fullscreenIdx >= 0) {
      // this.blockParentScroll(true);
      return (
        <div
          className="pro-fullscreen-wrapper"
          onWheel={this.props.onWheel}
          style={{
            opacity: this.props.fullscreenAnimating ? 0 : 1,
            transition: 'opacity 1s ease',
          }}
        >
          <ProFullscreen
            items={items}
            initialIdx={fullscreenIdx}
            initialBrowserExpanded={false}
            actions={this.actions}
            container={this.state.container}
            locale={'en'}
            deviceType={'desktop'}
            styles={styleParams}
          />
        </div>
      );
    } else {
      // this.blockParentScroll(false);
      return null;
    }
  }
}
